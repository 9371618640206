
import Vue from 'vue';

import PageTitle from '@/components/atoms/PageTitle.vue';
import { schoolServiceModule } from '@/store/dataModules/schoolServiceModule';
import { UniqueServiceMastResponse } from 'chaild-api/lib';

interface DataType {
  isDialogOpen: boolean;

  isUpdating: boolean;
  isDeleting: boolean;

  editingService: UniqueServiceMastResponse | null;
  deletingService: UniqueServiceMastResponse | null;
}

export default Vue.extend({
  components: {
    PageTitle,
  },

  data: (): DataType => ({
    isDialogOpen: false,

    isUpdating: false,
    isDeleting: false,

    editingService: null,
    deletingService: null,
  }),

  computed: {
    services() {
      return schoolServiceModule.schoolServices;
    },
    editValid() {
      if (
        this.editingService
        && this.editingService.uniqueServiceName.trim().length > 0
      ) {
        return true;
      }
      return false;
    }
  },

  methods: {
    getServiceTypeString(serviceType: string) {
      switch (serviceType) {
        case 'perUse':
          return '回数';   
        case 'monthly':
          return '固定月額';
        case 'useMonth':
          return '利用月額';   
        default:
          return '';
      }
    },
    getServicePaymentMonthString(paymentMonth: number) {
      switch (paymentMonth) {
        case 0:
          return '同月';   
        case 1:
          return '翌月';
        case 2:
          return '翌々月';   
        default:
          return '';
      }
    },
    setEditingService(service: UniqueServiceMastResponse | null) {
      this.editingService = service;
    },
    async saveEditingService() {
      if (this.editingService) {
        this.isUpdating = true;
        await schoolServiceModule.updateSchoolService(this.editingService);
        await schoolServiceModule.listSchoolServices();
        this.isUpdating = false;
        this.editingService = null;
      }
    },
    setDeletingService(service: UniqueServiceMastResponse | null) {
      this.deletingService = service;
    },
    async deleteService() {
      if (this.deletingService) {
        this.isDeleting = true;
        await schoolServiceModule.deleteService(this.deletingService);
        await schoolServiceModule.listSchoolServices();
        this.isDeleting = false;
        this.deletingService = null;
      }
    },
  },

  mounted() {
    schoolServiceModule.listSchoolServices();
  }
});
