/**
 * おたより
 */

export const monthItems: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const templateText = {
  nurseryLetter: {
    1: [
      {
        title: "",
        body: "いよいよ新しい１年の始まりです☆\n今年はどんな年になるのかな？どんな楽しいことが待っているのかな？\n子どもたちも職員も、みんながワクワクしています！\n日々の生活の中で見られる子どもたちの、さらなる成長とたくさんの笑顔を\n見守り、支えて、元気いっぱいの保育をしてまいりますので、\n今年もよろしくお願い致します！",
      },
      {
        title: "１月の予定",
        body: "～３日　冬休み　　　　　　　　　　　　　６日　身体測定\n９日　避難訓練　　　　　　　　　７～１０日　お正月遊び週間\n１３日　成人の日（休園）　　　　　　　１６日　お誕生会\n２０～２９日　個人面談（希望者のみ）　　　　２３日　JR見学（予定）",
      },
      {
        title: "★お正月あそび週間★",
        body: "７日～１０日までは“お正月週間”と題し、\nお正月ならではの遊びを楽しむ予定です。\nお父様やお母様の小さかったころと同じような、伝統ある遊びを行うことで、\n子どもたちが日本の文化に触れるいい機会となることを願っています☆\n福笑い、こま回し、凧あげなどなど…\n冬休みの間に、ご家族で遊ぶのも楽しいでしょうね！",
      },
      {
        title: "ＰＭ２.５にご注意！！",
        body: "ニュースなどで話題のＰＭ２.５についてご存知ですか？\nＰＭ２.５とは、大気中をただよう物質のうち、直径が ２.５μm 以下という、とっても小さな粒のことです。\n工場や排気ガス等に含まれる有害物質で、吸い込むと肺の奥まで達し、気管支炎や肺ガンのリスクがかなり高まります。中国からの偏西風などにのって、最近では県内でも注意喚起が必要とされるなど大気汚染が深刻化しています。この大気汚染により、子どもやお年寄りは、重い病気を発症したりする恐れがあります。\n当保育園でも、ＰＭ２.５ などの大気汚染についての情報を日々確認し、濃度が高い日には外遊びや換気を控えるなど工夫してまいります。子どもたちの安全と健やかな体作りのために、引き続き注意していきたいものです。",
      },
      {
        title: "おねがい",
        body: "冬休みの間は、お正月ということもあり、おいしい御馳走の食べ\n過ぎや、寝る時間や起きる時間のずれから、生活のリズムが変わ\nりがちです。\n生活のリズムが乱れると、体調不良や、たくさんの人ごみに行っ\nたときなどに感染症にかかりやすくなるということもあります。\n生活のリズムが保つため、規則正しい生活を心掛けましょう。\nまた、９時３０分までの登園も、大切な生活のリズムのひとつ\nですので、引き続き、ご協力お願い致します。\n元気な笑顔で登園してくれるみんなを待っていますね☆",
      },
      {
        title: "",
        body: "",
      },
      {
        title: "",
        body: "",
      },
    ],
    2: [
      {
        title: "",
        body: "　まだまだ寒い日が続きますが、暦の上では立春を迎えようとしています。梅の花が咲き始めるのも、もう少しですね！\n寒さに負けず、こども達は公園でも元気いっぱい過ごしています。園では加湿や手洗い・うがいを励行し感染症予防を行っています。ご家庭においても予防に心がけていただきこの季節をのりきりましょう！",
      },
      {
        title: "２月の行事予定",
        body: "３日～５日　身体測定　１４日　バレンタインデー\n３日　節分　　　　　　２０日　お誕生日会\n６日　避難訓練",
      },
      {
        title: "２月３日は節分の豆まきをしましょう！",
        body: "節分は、季節の変わり目のことでもともとは立春、立夏、立秋、立冬の、年に４回ありましたが今では立春の前日だけを指すようになったそうです。悪いもの（鬼）をだし、良いもの（福）を呼ぼうと豆まきをします。豆まきの大豆には、芽を出してぐんぐん伸びる強さがあり、栄養もたっぷりです！\n悪い鬼は外へ払い、福の神様には、楽しい事がたくさんありますようにと願いを込めて豆まきをしていきたいですね！保育園でも子どもたちの心の中にいる泣き虫鬼、甘えんぼ鬼、いじわる鬼などをやっつけられるよう豆まきをして、年齢の数だけ豆を食べ今年一年笑顔で元気いっぱい健康に過ごせるようみんなで願い、節分の行事を行っていきたいと思います。",
      },
      {
        title: "丈夫な体作りをしていきましょう",
        body: "一年の中でも一番寒さの厳しい季節となり、低温、低湿のこの時期は、かぜウイルスが最も活発になる季節です。かぜを寄せ付けないじょうぶな体作り、環境作りに気を配り寒い冬を元気に乗りきりましょう",
      },
      {
        title: "引き続き感染性胃腸炎にご注意下さい！",
        body: "感染性胃腸炎はこの時期とても感染しやすく、これか\nら春先にかけて　ロタウイルスによる感染性胃腸炎が\n流行します。引き続き感染予防に努めていきましょう。予防には手洗い、便や嘔吐物の適切な処理と、塩素系漂白剤による消毒が有効になります。また下痢が続く場合は水分補給を十分に行い、おむつかぶれなどお尻のケアにも留意していきたいですね。",
      },
      {
        title: "☆乾燥が気になる季節のスキンケアのポイント☆",
        body: "皮膚を清潔に保つ事が一番大切な事だと思います\nが、お風呂でごしごし皮膚を刺激しすぎないよう\n泡で優しく洗います。保湿効果のある入浴剤など\nも上手に利用すると良いですね！\nお風呂から上がった後、朝の着替えの時などに刺激の少ない保湿クリームをこまめに塗って乾燥を防げるようケアをしていきたいですね！また、下着は刺激の少ない木綿が良く清潔にきちんと着ると効果的に保湿ができて心地良く過ごせそうですね。",
      },
      {
        title: "おねがい",
        body: "先日、手紙で配布させていただきました新年度アンケートの提出締め切りは、１７日（木）となっております。また、退園をお考えの方は退園希望月の２０日までに退園届けを提出していただきますので、よろしくお願い致します。\nご不明な点などありましたら職員にご相談下さい。",
      },
    ],
    3: [
      {
        title: "",
        body: "日中の穏やかな日差しの中に春の訪れを感じるようになりました。月日が経つのは早いもので今年度も終わろうとしていますが、子ども達の成長がなによりたのもしく嬉しく感じます。いろいろな行事を含めこの一年間を無事に送ることができたのも、保護者の皆様の暖かいご理解とご協力があってこそだと職員一同心より感謝しております。来年度も子ども達ひとりひとりが安全で楽しく過ごせるようにしていきたいと思います。",
      },
      {
        title: "３月の予定",
        body: "３～５日(月～水)　身体測定　　　２０日(木)　お誕生日会\n６日(木)　　　避難訓練　　　２１日(金)　春分の日\n９日(日)　　　遠足　　　　　３１日(月)　お別れ会",
      },
      {
        title: "「ひなまつり」",
        body: "ひなまつりは女の子の健やかな成長を祈る節句の年中行事。ひな人形は子どもに降りかかる厄(よくないこと)を身代わりに受けてくれるとされています。縁起の良い意味が込められたひな祭りの食べ物、\n≪ひなあられ≫≪ちらしずし≫≪はまぐりのお吸い物≫等々を食べて、男の子も一緒にお祝いしてくださいね",
      },
      {
        title: "引き続きご注意ください！",
        body: "●インフルエンザ・・・　継続的に流行しています。\n●感染性胃腸炎・・・　伝染力が強く家族全員でかかる例もあります\n●鼻かぜ、アレルギー性鼻炎・・・　青っぱな、水ばななどの鼻水がでて、夜間痰のからんだ咳がでるかぜ。鼻づまりや咳がひどくて眠れない場合もあります。\n●皮膚の乾燥・・・　空気の乾燥や汗をかかないことなどで頬がカサカサしてきたり、アトピー性皮膚炎の悪化がみられます。\n普段から出来る手洗いやスキンケアをしっかりして\n予防していきましょう！\n人の多い所ではマスクを必ず着用しましょう！",
      },
      {
        title: "春の遠足",
        body: "３月９日(日)葛西臨海水族園に遠足に行きます。\nご家族と一緒に、水族館を見たり、お弁当を食べたり♪\n詳しいお知らせは別紙にて配布しました。ぜひご参加下さい♪♪",
      },
      {
        title: "おしらせ",
        body: "ご存知の方もいらっしゃると思いますが、このたび、園長の田中が結婚いたしました。年度途中でもありますので３月末までは苗字を変更せず、新年度となる４月に、新しい苗字の　山田　に切り替えます。\n田中あてに、たくさんのお祝いメッセージをありがとうございました。今後ともよろしくお願いいたします。",
      },
    ],
    4: [
      {
        title: "",
        body: "風がほんわか暖かくなってきました。お散歩に出かけると、どこからか匂う花の香り、木々の芽咲きに春の訪れを感じます。新しく新年度を迎える子どもたちの胸の中にも、春の光が届いているかと思います。そのワクワクした気持ちを、職員も一緒に感じながら、子どもたちの成長を見守り、支えていきたいと思っておりますので、新年度もどうぞ宜しくお願いいたします。",
      },
      {
        title: "４月の予定",
        body: "1～3日　身体測定　８日　お花見遠足　９日 避難訓練\n18日　お誕生日会　　園児健康診断（下旬予定）",
      },
      {
        title: "お花見を楽しみましょう♪",
        body: "◎桜が綺麗に咲く季節がやってきました。日本の伝統行事として昔からお花見の習慣があります。農繁期で忙しくなる前のひとときの憩いと桜の木の下で楽しむことで厄を祓い神様と過ごすとされてきたのです。今では桜の木の下で美味しい食べ物をいただき、春の憩いの時間を楽しまれています。\n◎園でも、お花見遠足を企画しています。子どもたちがにぎったおにぎりを持って、近くの公園へ出かけます。　　桜の木の下で春を感じながらおにぎりを食べ、\nお花見を楽しみたいと思っています♪",
      },
      {
        title: "栄養のある食生活を心がけましょう！",
        body: "★栄養は食べ貯めができない★\n本来食品とは、様々な栄養素が複合的に働きあって、全体の機能がうまくいくように仕組まれており、人が生きるための必要な栄養素は、体の中で貯蔵したりすることがありません。そのため、毎日の食事から栄養を摂らなければならないのです。\n★ビタミンが足りないと・・・★\n・ビタミンＡ…夜目が見えにくくなる。\n・ビタミンＢ…疲れやすくなる。\nかっけという病気になる。\n・ビタミンＣ…歯茎から血が出やすくなる。\n※ビタミンは、水や熱に弱いので、生で食べられる野菜は、なるべく生のままで食べましょう。\n病気にならないためにも、体に必要な栄養素をバランスよく摂れる食事を心がけたいですね。",
      },
      {
        title: "園から保護者のみなさまへ",
        body: "　冬の寒さから春の暖かな気候へと変わりはじめ、\n衣替えの季節となりました。まだ、風が冷たくて\n寒い日もありますが、汗ばむ日もあります。なるべく薄着を心がけ、気候に合わせた服装で過ごしていきたいですね。\n園でも温度や湿度に合わせて服装を調節していこうと思います。季節の変わり目ですので、厚手と薄手のお洋服両方をお持ちいただければその都度、園で調節することが可能ですので一枚ずつ予備としてお持ち下さい。\n新年度が始まるにあたり、もう一度身の回りの持ち物全てにお名前が書いてあるかご確認下さい。",
      },
      {
        title: "",
        body: "",
      },
    ],
    5: [
      {
        title: "",
        body: "新緑の美しい季節となりました。爽やかな風も吹き、元気なこいのぼり\nが空を泳ぎ回るシーズンを迎えています。\n入園式から、もうじき１ヶ月。新入園児のお友だちも在園児のお友だち\nも、みんな笑顔で声を掛け合い、仲良く遊んでいます。\nこの時期は、日中は温かくても朝晩に冷え込むことも多いので、体調は\n要注意。お子様の健康状態には十分に気をつけていきましょう。",
      },
      {
        title: "５月の予定",
        body: "１～７日　身体測定　　　　　　　２０日～　個人面談\n９日　避難訓練　　　　　　　２３日　お誕生日会\n１６日　ピーマン・トマトの苗植え",
      },
      {
        title: "持ち物の名前、薄くなっていませんか？",
        body: "お子様が保育園で使っているタオル、洋服、布団などで、名前の記入が薄くなり誰の持ち物なのか、わからなくなってしまうことがあります。\n名前の記入漏れがないか　／　名前が薄くなっていないか　　　など、\n新年度の始まりを機に、持ち物すべて、名入れの確認をお願い致します。",
      },
      {
        title: "ほけんコーナー",
        body: "５月は、連休など不定期な休日もあり、生活リズムが\n乱れがちな季節です。昼と夜の気温差も大きいために\n保育園の子どもたちに生活の疲れが出やすくなります。\nご家庭でも、休息や睡眠を十分にとって疲れを残さず、\n生活リズムも整えるなど、体調維持を心がけましょう。\n先週から、急な発熱や嘔吐下痢による休みが急増中\nです。うがい手洗い励行も、引き続きお願い致します。\n◎ 週に一度は爪をきりましょう。\n爪を伸びたままにしていると、割れたり、お友だちを\nひっかいてしまうこともあります。\n爪切りは週に一度が目やすです。お子様の爪をこまめ\nにチェックする習慣をつけましょう。",
      },
      {
        title: "５月５日は子どもの日",
        body: "　鯉のぼりには、『人生という流れの中で遭遇するさまざまな難関を、\n鯉のように突破してほしい』と、立身出世の願いがこめらています。\nもともと将軍家の中に、男の子が生まれたときの記念として旗指物\n（はたさしもの）や幟（のぼり）を立てて祝う風習がありました。\nやがてこれが武家にも広がり、男の子が生まれたときの〝しるし〟\nとして幟を立てるようになったそうです。",
      },
      {
        title: "食育を楽しみましょう！",
        body: "５月１６日に、トマトとピーマンの苗植えを行います。毎年、子ども\nたちが愛情たっぷり育ててくれているものです。ご家庭でもお子様と\n一緒に親子クッキングなどいかがでしょう？『親子で食欲か増えた』　　　『達成感を味わえた』などの感想が毎年寄せられています。",
      },
    ],
    6: [
      {
        title: "",
        body: "新しいクラスも、もう２ヶ月め。新入園の子どもたちも、園の生活に慣れてきたようです。園では近ごろ、ブロックやおままごとなど普段の遊びを通して、クラスのお友だちとの関わりを楽しむ子どもを見かけるようになりました。\nこれから梅雨の季節に入り、雨で外遊びが難しい日も増えてきそうですが、室内で静かに遊べる遊びを工夫したり、子ども体操やダンスを楽しんだりしながら、子どもの〝元気〟をしっかり育ててまいります。",
      },
      {
        title: "６月の予定",
        body: "１～５日　身体測定　　　　　　　　１５日　父の日の紙工作\n４～８日　歯と口の健康週間　　　　２０日　お誕生日会\n８日　時の記念日の製作　　　　２７日　夏祭り\n１２日　避難訓練",
      },
      {
        title: "カビや食中毒にご注意ください!!",
        body: "　梅雨時、気温も湿度も高まるこの時期は、カビや食中毒に要注意！\nポイント①：カビを防ぐ\n換気や風通しをよくして、水分を残さず、カビを見つけたら、\nこまめに掃除します。除菌剤や除湿剤、除湿機も効果的です。\nポイント②：食中毒を防ぐ\n料理の前にしっかり手洗いし、食材は内部まで熱を通します。\nまた、洗い物をため込まないこと。冷蔵庫や調理器具を、定期\n的にアルコール消毒するのも効果的です。",
      },
      {
        title: "★しっかり噛んで食べよう★",
        body: "食べ物をよく噛むと、いいこといっぱい♪\n\n◎　おいしさをより強く感じられるようになります。\n◎　唾液が増えて、消化が楽になり、虫歯菌の繁殖も防げます。\n◎　唾液に含まれるホルモンが、頭の働きをよくします\n◎　歯やあごの骨が育ち、言葉の発音がはっきりしてきます。\n近ごろは、柔らかい食べ物ばかりが食卓に並びがち。この機会に、\n親子で「よく噛む」ことを習慣づけてみませんか♪",
      },
      {
        title: "★異年齢交流ご報告★",
        body: "５月から始めた異年齢交流について、「新鮮で、良い経験になった」\n「子どもが楽しんでいる」など、うれしい声が届きました！\n保育園でも、お兄さん／お姉さんらしく、小さいお友だちに優しく接\nする姿や、大きいお友だちの仕草を真似する姿など、今までにない様\n子が数多く見られるようになりました！\nこれからも、子どもたちが負担なく楽しめる範囲で、異年齢の関わり\nを増やしていく予定です。",
      },
      {
        title: "★園からのおしらせ★",
        body: "「みなさまの声」というアンケートをお配りしました。\n園をご利用いただく中で、お気づきの点等ございましたら、\n本アンケートにご記入のうえ、園の回収箱にご投函ください。\n※　回収箱は、そら組、にじ組の各部屋にあります。\n「こんなことが出来るようになった！」など、保護者の皆さまから\nの、うれしいご報告も、もちろん大歓迎です。\nより良い保育のために、貴重なご意見をぜひお聞かせください。",
      },
    ],
    7: [
      {
        title: "",
        body: "梅雨入り以来、ジメジメと蒸し暑い日が続いていますが、そんな中でも、子どもたちは元気いっぱいに遊んでいます。戸外では隠れんぼや遊具あそび、室内では運動あそびやごっこ遊び、と、仲良く遊ぶ姿が園のあちこちで見られます。\nこの梅雨が明けると、本格的な夏がやってきます。子どもたちの大好きなプールあそびも始まります。水分補給や紫外線対策など、健康と安全に気を付けて、毎日を楽しく過ごしてまいります。",
      },
      {
        title: "7月の予定",
        body: "２～４日　身体測定　　　　　　　　１９日　お誕生日会\n５日　七夕かざり製作　　　　　２４日　プール開き\n１１日　避難訓練\n１６日　ピーマン・トマトの収穫＆ピザ作り",
      },
      {
        title: "★☆７月７日は七夕の日☆★",
        body: "七夕は、織姫と彦星が天の川で一年に一回だけ出会える物語です。\n二人は結婚して以来、仕事を怠けて、二人一緒に遊んでばかりいました。\n織姫の父は、その堕落ぶりに喝を入れようと二人を引き離し、天の川の両岸に離れ離れにしてしまいました。二人は大いに反省し、再会を願って一生懸命に働いたため、年に一度、７月７日にだけ会えることになりました。そこから、願い事を短冊に書いて笹に結びつけるという七夕の行事ができた、と伝えられています。\nお家でも、お子さまとお話したり、お願い事を短冊に書いたりして、七夕の夕べを楽しんで下さいね。",
      },
      {
        title: "★気をつけよう！夏の感染症★",
        body: "これから暑くなると、水あそび、プールあそび、子どもたちにとって楽しい夏の遊びが沢山。しかし、体力の消耗の激しい時期でもあります。元気そうに見えても体は疲れ切っていることも。体調の変化には十分に気をつけてあげたいですね。\n◎ 咽頭結膜熱（プール熱）\n症状……３９℃以上の発熱、のどの痛み、目のかゆみ、ほか、\n充血や涙など、結膜炎のような症状が特徴です。\n◎ 流行性角結膜炎（はやり目）\n症状‥‥まぶたの腫れや異物感、痛み、充血。目やにで目が開\nけられない、など。発熱や下痢を伴うこともあります。\n◎ とびひ\n原因‥‥虫刺されや、湿疹をかきむしった後に、ばい菌が感染\nして起こります。化膿が進むと広がります。要注意！",
      },
      {
        title: "★夏野菜を食べよう！★",
        body: "毎年やってくる夏。暑いと、どうしても食欲がふるわず、体力も落ちてしまいます。食べないと健康な体を維持できないけど、だからと言って、そうめんや冷奴のような冷たいものですませてばかりいると、夏バテが‥‥。\nそんな時には、夏野菜！　夏野菜の代表といえば、トマト・ピーマン・きゅうり・ナス・おくら・とうもろこし・かぼちゃ・ニラなど。これらには、カロチン・ビタミンＣ・ビタミンＥが多く含まれていて、夏バテに効果的ですよ。\n園で６月に植えたピーマンとトマトの苗に、小さな実がつきました！\n毎日みんなで「大きくな～れ！」と声を掛けながら水やりをしているので愛着がわいてきて、成長するのを楽しみにしています。\n今月は育ったピーマンとトマトを収穫して、ピザ作りをする予定です♪",
      },
      {
        title: "",
        body: "",
      },
    ],
    8: [
      {
        title: "",
        body: "梅雨が明けていよいよ夏本番！　陽ざしが日増しに強くなる中でも、\n園の子どもたちは、元気よく水遊びを楽しんでいます。それこそ、夏の暑さを吹き飛ばすくらいに元気ですが、その一方で、しっかりとした休息の確保も必要です。そもそも、夏の暑さは、大人にとっても過酷なもの。いつにも増して「食事・睡眠・休養」を大切にして、楽しい夏にしていきましょう。　",
      },
      {
        title: "８月の予定",
        body: "１日～　身体測定・避難訓練　　　２３日　すいか割り\n２日　　運動保育　　　　　　　　２９日　お誕生日会\n７日　　交通安全教室　　　　　　３１日　やさいの日",
      },
      {
        title: "きちんと食べよう朝ごはん",
        body: "暑くて食欲がない、朝からずっとお腹がすかない‥‥暑い夏にはよく\nあることですが、そんなときでも、朝食は欠かせないもの。\n一日の始まりの食事ですから、大切にしていきたいですね！\n◎朝ごはんを食べると…\n☆　すがすがしい目覚めを迎えることができる\n☆　元気いっぱい活動的にすごせる\n☆　集中力が高まる\n☆　すっきりと　うんちがでる",
      },
      {
        title: "食中毒にご用心",
        body: "気温も湿度も高くなり、食中毒の心配がピークとなる月を迎えています。\n体調を崩すなどして抵抗力が弱っているときも、食中毒は起きやすくなりますので、体調管理には特に気をつけていきましょう。\n◎　食中毒予防の三原則\n①　食中毒菌をつけない‥‥\n・手指や調理器具はよく洗浄し、消毒しましょう。\n（生魚、生肉、卵を扱ったあとは特に念入りに)\n②　食中毒菌を増やさない‥‥\n・調理後はできるだけ早く食べましょう。\n・食べ残しを保存する場合は、室内に置かず、冷蔵庫に\n５℃以下で保存しましょう。\n・冷蔵庫に詰め込み過ぎない。温度が下がらなくなります。\n③　食中毒菌をやっつける‥‥\n・調理の時に、食品の中心まで、しっかり火を通しましょう。",
      },
      {
        title: "夏ならではの楽しい経験を",
        body: "海や山、小旅行に夏祭りなど、夏はさまざまな人と\n触れ合う機会も多く、人に対する愛情や信頼感が育\nつ、最高の季節です。保育園では経験できないような事をいろいろ体験し、無理のない計画で楽しくお過ごしください。",
      },
      {
        title: "プール遊びのあとは疲れています",
        body: "プール遊び、水遊びのあとは、大人でも手足がだるくなったり、全身の力が入りにくくなったりするものです。\n元気いっぱいに見える子どもたちも、プール遊びや水遊びの後は、かなり疲れています。園でも、しっかりと午睡時間を確保してまいりますが、\n帰宅後も、ぜひ体調回復にご留意を。また、早寝、早起きなど規則正しい生活も、併せてお願いいたします。",
      },
    ],
    9: [
      {
        title: "",
        body: "暑い日がまだまだ続きますが、子どもたちはお気に入りの水着を着て、\n水遊びにプール遊び、と元気いっぱい。こちらまで楽しくなってしまいますが、熱中症にだけはご用心。水分補給と休息をしっかりとって、暑さに負けずに元気よく過ごしていきましょう。\n私ごとですが、先月から当園で勤務開始しました。毎日かわいい子どもたちと過ごせて、うれしく思います。より安心してお預けいただける保育園を目指し、日々勉強してまいります。今後とも、どうぞよろしくお願いいたします。",
      },
      {
        title: "９月の予定",
        body: "１日　身体測定（～４日）　　１８日　敬老の日（休園）\n５日　避難訓練　　　　　　　１９日　お誕生日会\n９日　運動会　　　　　　　　２４日★秋分の日（休園）\n★‥‥振替休日",
      },
      {
        title: "☆お知らせ☆",
        body: "夏の暑さが和らいでくると、いよいよ運動会シーズン到来ですね！\n今年の運動会は、今月９日（日）の予定です。\n園では、運動会に向けて練習が始まりました。子どもたちは保護者の皆さんに活躍する場面を見てほしくて、リレーの練習や運動保育を、それはそれは頑張っています。お子様の成長ぶりをご覧いただける機会と思いますし、かわいいダンスは当園自慢の見どころ！ぜひお越しください。\n☆　詳しい日程は、後日プログラムを配布します。\n☆　昨年とは、場所が異なります。十分お気をつけ下さい。",
      },
      {
        title: "夏カゼってこわいの？",
        body: "夏カゼは、赤ちゃんや老人など抵抗力の弱いかたに多く見ら\nれる病気ですが、若いかたでも、夏バテで体力が落ちたとき\nなどは要注意。寝冷えや強すぎるエアコンは避けましょう。\n¶ 症状としては‥‥\nいわゆる風邪の諸症状（発熱、咳鼻水、喉の痛み）。\n下痢などの胃腸症状が加わることも。\n¶ こじらせると‥‥\n高熱、肺炎や髄膜炎の合併症の危険。重症化に注意。\n¶ 予防するには‥‥\nうがいと手洗い。まず何より、規則正しい生活！\n汗の始末もしっかりとして夏カゼ対策をしていきましょう。",
      },
      {
        title: "★9月18日は敬老の日★",
        body: "敬老の日は、１９６５年に「多年に渡り社会につくしてきた老人を敬愛し長寿を祝う日」と法律で定められました。当園では、敬老の日に向けて大好きなおじいちゃんおばあちゃんのことを思って、みんなでプレゼントを作る予定です。\nご家庭でも、お子様と一緒に日ごろの感謝をこめて、たくさんの『ありがとう』を伝えていけると良いですね！",
      },
      {
        title: "食欲の秋！収穫の秋！",
        body: "季節は食欲の秋。旬の食べ物といえば？\nぶどう・かき・なし・りんご・栗などなど、この時期は、おいしい食べ\n物がたくさんありますね。そんな旬の味覚を味いたいなら、「○○狩り」\nに出掛けるのも楽しいかもしれません♪　戸外でおいしいごはんやフルー\nツなどを食べるピクニックも、この時期が一番ですよね。\n園でも、秋の味覚を使ったクッキングや食事など、食育に、よりいっそ\nう力を入れていきたいと考えています。",
      },
    ],
    10: [
      {
        title: "",
        body: "朝晩が少しずつ涼しくなり、秋のおとずれを感じる季節となりました。毎日楽しかったプール遊びも終わり、戸外遊びでは涼しい風に気持ち\n良さを感じながら、散歩や木の実探しを楽しんでいます。これからの\n季節には「～の秋」がたくさん待っています！\nご家族で夢中になれる秋を見つけられるといいですね。",
      },
      {
        title: "１０月の予定",
        body: "１～３日　身体測定　　　　　　１７日　お誕生会\n７日　避難訓練　　　　　　２４日　秋の遠足\n１０日　ホットケーキ作り　　３１日　ハロウィンパーティー",
      },
      {
        title: "油断禁物です！！",
        body: "暑い夏が終わり、急に寒く感じることが増えてきましたね。\nそんな時期気を付けたいのが、体調不良です。\n昼間は暑く、朝晩は寒いといった不安定な時期に、水分補給や十分な休息を取ることを怠ると、熱中症のような症状や、風邪をこじらせたような症状がでることがあります。まだまだ、油断はせず、十分な水分補給と、休息、気温にあった衣服の調節に気をつけて行きましょう",
      },
      {
        title: "★秋の遠足★",
        body: "１０月２４日(木)　秋の遠足に出かけます！！\n行き先　　磯辺新田公園　　　出発時間　９時３０分\n持ち物　お弁当、水筒、おしぼり、レジャーシート\n秋の涼しい風を感じながら普段とは違う道を歩き、新しい発見をお友だちや保育者と一緒に楽しみたいと思います。広い公園で思いきり遊んだ後、お昼にはお家から持ってきたお弁当を気持ち良い空の下で食べますので、お弁当の準備を忘れずにおねがいします！＊お手数をおかけしますが、そら組さんはおにぎり・つまんで食べやすいおかずのご用意をお願い致します。",
      },
      {
        title: "楽しいお弁当作り！",
        body: "遠足当日のお弁当は子どもたちにとって最大の楽しみかもしれません。遠足の何日も前からお弁当にいれるおかずについて話したり、その作り方について話したりする時間も、遠足を楽しみにするきっかけとなりそうですね。また、こうして楽しく食について話す時間は自然に身に付く食育となることでしょう☆\n＊お菓子などのおやつは、ご用意頂かなくて大丈夫です。\n＊持ち物には必ずお名前をご記入下さい。",
      },
      {
        title: "・おしらせ・",
        body: "７月に入社致しました、鈴木花子先生が、体調不良により8月をもちまして退職することとなりました。また、突然のことにより、当人からの挨拶ができず申し訳ございませんがご了承頂きます様お願い致します。今後とも、保育園職員一同、日々の保育にせていただきますので、どうぞよろしくお願い致します。",
      },
    ],
    11: [
      {
        title: "",
        body: "今年の秋は暑かったり寒かったり、温度差の強い日が続きますね。みんなが大好きなロケット公園の木々たちは秋風に吹かれ落ち葉をたくさん落とし始めています。これから寒くなっていきますが木の葉や木の実に触れ、北風も頬にたくさん感じながら元気に体を動かし、体調を崩さない様しっかりと体調管理をしていきましょう！",
      },
      {
        title: "１１月の行事予定",
        body: "１～７日　身体測定・避難訓練　　　　　１４日　消防署見学\n３日　文化の日　　　　　　　　　　２１日　お誕生日会\n４日　お誕生日会　　　　　　　　　２３日　勤労感謝の日",
      },
      {
        title: "１１月２３日は勤労感謝の日",
        body: "勤労感謝の日は「勤労を尊び、生産を祝い国民が互いに感謝しあう」ことを目的に、国民の祝日と定められています。私たちが生きていくために必要なもの（衣食住）は、すべて誰かの労働（仕事）によって生み出されていると言っても過言ではありません。ご家庭でもお父さん、お母さんがどんなお仕事をしているのかお子さんとお話をしてみたり、家族でたくさんの「ありがとう」を伝え合っていきたいですね！\n勤労感謝の日にちなんで、当保育園では今年も近所にある◎◎消防署△△出張所へ見学に行き、地域の安全を守ってくれている消防士さんのお仕事のお話を聞かせていただく予定です。消防車を近くで見たり、消防服を着せてもらったり、貴重な体験ができそうです。記念のお写真もたくさん撮ってきますね！　",
      },
      {
        title: "冬に注意したい感染症",
        body: "インフルエンザとは…\nインフルエンザウイルスによる気道感染症。突然の発熱で始まり\n38度以上の高熱、上気道炎症状、全身倦怠感などの全身症状があ\nらわれる。発熱は３日ほど続き高齢者や乳幼児が発症すると肺炎\nや脳症を引き起こす場合もある\n感染経路…主に直接飛沫感染でウイルスを口や鼻から吸い込んで感染する\n潜伏期間…18時間～72時間。発症後２～５日間はウイルス排出の危険がある\nＲＳウイルスとは…\n軽い鼻かぜから始まり、咳が出てゼーゼーと息するようになる。\n６か月未満の乳児は感染すると呼吸困難になったり細気管支炎、\n肺炎、中耳炎などを引き起こして重症化することもある\n感染経路…飛沫感染や接触感染\n潜伏期間…２～８日",
      },
      {
        title: "感染症を防ぐ予防方法",
        body: "①外出後の手洗い・うがいを行いましょう\n②睡眠を十分にとりましょう\n③人ごみをなるべく避け、マスクなどをして出かけましょう\n④バランスのいい食事を心がけましょう\n⑤室内を適度な温度(50％～60％)に保ちましょう\n⑥嘔吐物や便で汚染されたと思われる場所は適切に処理して感染\nを広げないようにしましょう\n※次亜塩素ナトリウム液(塩素系漂白剤)で、念入りにお掃除を！\n⑦症状がある間は入浴を控え、タオルの共用などを避けましょう",
      },
      {
        title: "",
        body: "これからの季節、寒くなるにつれて厚着をしないと！\nと思いがちですが、子どもたちは元気いっぱいに体\nを動かしています。厚着をするとすぐに汗をかき、\n汗が冷えると風邪をひく原因にもなります。\nまた、着せすぎは体の自由な動きを制限することも\nあります。寒さに強い体を作るためにも、昼間は\n薄着で過ごせるようにしていきましょう！",
      },
    ],
    12: [
      {
        title: "",
        body: "日に日に寒くなり、朝夕の冷たい空気が本格的な冬の訪れを感じさせます。今年も残りわずかとなりましたが、今月は子ども達が楽しみにしているクリスマスがあります。歌や絵本、製作を通して楽しみにしている気持ちを共有して楽しんでいきたいものです。また、１２月は、空気の乾燥に伴って風邪やインフルエンザが流行し始める時季でもありますので、ビタミン豊富なものや温かい食べ物をたくさん食べて、元気に冬休みを迎えましょう。",
      },
      {
        title: "１２月の予定",
        body: "２～４日　　身体測定　　　　　　２３日　　天皇誕生日　\n５日　　避難訓練　　　　　　２５日　　パフェづくり\n１５日　　クリスマス会　　　　２９日～　冬休み( 1/3 まで)\n１９日　　お誕生日会",
      },
      {
        title: "冬至のお話",
        body: "今年は≪12月22日≫です。\n冬至のこの日、北半球では太陽の高さが一年中で最も低くなり、昼が一年中で一番短く、夜が一番長くなります。\nさて、冬至には、かぼちゃを食べ、柚子湯に入る習慣があります。\nかぼちゃには体内でビタミンに変化するカロチンが多く含まれています。冬にビタミンが不足しがちだった時代の、先人の知恵だともいえるでしょう。\nまた、柚子湯は、寿命が長く病気にも強い柚子の木にならった風習で、柚子風呂に入って無病息災を祈るようになったと言われています。柚子のいい香りでゆっくりお風呂に入ればリラックスできて風邪予防にもなりますよね。",
      },
      {
        title: "しっかり対策！インフルエンザ",
        body: "いったん流行すると急速に広がるのがインフルエンザ。\n風邪と違って全体に症状が重くなりがちで、いったんインフ\nルエンザにかかると４～５日の病欠が必要になってしまいます。\nくしゃみや咳による飛沫（ひまつ）感染タイプで、ウィルスが\n付着した手が口などにふれることが主な原因といわれます。\n今年は早い時期から流行する予測があり、すでに県内では、\n学級閉鎖がでているようです。\nインフルエンザを避けるには基本的な予防や体調管理が重要\nです。早めの予防対策をしっかりしていきましょう。\n症状⇒　倦怠感、関節の痛み、高熱、せき・鼻水・のどの痛み\n種類⇒　Ａ型・Ｂ型・Ｃ型\n予防⇒　　予防接種： 12月上旬までに接種することが望ましい\n手洗い： 外出後や食事の前、トイレの後などに、石けん\nで、こまめに手を洗う。\n手のひらだけでなく指先、爪の間、指の間まで\n忘れずに洗うこと\n適度な湿度： 室内で加湿器を利用し、適度な湿度を保つ",
      },
      {
        title: "クリスマス会について",
        body: "今年も◎◎園と合同で１２月１５日、△△市民会館でクリスマス会を行います。\n詳細や出欠確認のお手紙は、後日の配布となります。\nまた、現在、空のティシュＢＯＸを集めていますので、\nお家に余っていましたらご提供とご協力お願い致します。\n子どもたちは、先月から歌や劇の練習を始めています。\n当日の本番を楽しみにしながら、\nぜひクリスマス会にご参加ください。 ミ☆　ミ",
      },
      {
        title: "",
        body: "",
      },
    ],
  },
  healthLetter: {
    1: [
      {
        title: "",
        body: "あけましておめでとうございます。年末年始のお休みはいかがでしたか？　\n不規則な生活習慣がついてしまった……というお子さんはいませんか？\n毎日冷え込み、体調を崩しやすい時期です。気持ちも新たに、健康管理に留意しましょう。",
      },
      {
        title: "かぜとインフルエンザ",
        body: "●かぜは、アデノウイルス、ライノウイルス、ＲＳウイルスなどが原因で、微熱、鼻やのどの痛み、くしゃみ、鼻水、などが主な症状。\n●インフルエンザは、インフルエンザウイルスが原因で、40℃前後の高熱、悪寒、のどの痛み、せき、鼻水のほか、関節痛や筋肉痛を伴うのが特徴。",
      },
      {
        title: "冬の肌荒れ対策",
        body: "空気が乾燥する冬は、お肌も乾燥する季節。特に子どもは、肌のバリア機能が未発達のため、荒れやすいのです。日々のケアを心がけ、デリケートな肌を守りましょう。\n・外あそびや手洗いのあと、保湿クリームを塗る。\n・冬の寒風に地肌をさらさない。\n（長そで・長ズボンの着用）",
      },
      {
        title: "うがいと手洗い",
        body: "外出後は必ず、うがいとせっけんを使った手洗いを習慣にしましょう",
      },
      {
        title: "外出時にはマスクを",
        body: "感染予防にもなりますが、マスクのもっとも大きい効果は、ウイルスをまき散らさないことです。",
      },
      {
        title: "室内の温度・湿度を チェック",
        body: "室温16～18℃、湿度60％前後が目安です。特に乾燥には要注意。加湿器などをじょうずに使いましょう。",
      },
    ],
    2: [
      {
        title: "",
        body: "厳しい寒さの中でも、元気いっぱいな子どもたち。でも、低温・低湿の冬は、かぜウイルスが大好きな季節です。\nかぜを寄せつけないじょうぶな体作り、環境作りに気を配り、寒い冬を元気に乗り切りましょう。",
      },
      {
        title: "「かぜ」は油断大敵",
        body: "鼻やのどにウイルスや細菌が感染し、炎症を起こすものの総称を「かぜ症候群」といいます。軽く済むことも多いのですが、油断をすると悪化して、中耳炎、肺炎、気管支炎など、さまざまな病気を併発することもあります。\nちょっと体調が悪そうなときは、無理をせず安静を心がけ、長引かせないようにしましょう。日ごろから、規則正しい生活を送ることもたいせつです。",
      },
      {
        title: "おねしょ について",
        body: "子どもは膀胱（ぼうこう）が小さいうえに、尿量を調節する「抗利尿ホルモン」の分泌が不安定です。そのため夜間、無意識のうちに膀胱に入る以上の量が作られ、あふれてしまうことがあり、これがおねしょです。\n７歳くらいまでのおねしょは、あまり心配しなくて、だいじょうぶ。毎晩続くと、洗濯物などたいへんかと思いますが、大人が心配したり、しかったりすることで、子どもにストレスを与えないよう、気をつけましょう。",
      },
      {
        title: "３歳以降の肥満に 要注意！",
        body: "乳幼児期は体重の変動が激しく、しょっちゅう増えたり減ったりしますが、あまり心配はいりません。特に１歳くらいまでの肥満は乳児肥満といって、歩けるようになって運動量が増えると、どんどん体がしまってきます。\nただ、心配なのは、３～６歳にかけて太ってきた場合。そのまま肥満につながりやすいので、注意しましょう。",
      },
      {
        title: "食生活の見直しを",
        body: "スナック菓子やジュース類のとりすぎに注意しましょう。",
      },
      {
        title: "意識的に体を動かして",
        body: "肥満気味の子は、体を動かすことを避ける傾向があります。本人の好きなあそびで、楽しく体を動かすようにしていきましょう。",
      },
    ],
    3: [
      {
        title: "",
        body: "今年度も残すところあと１か月となりました。１年前を思い起こし、子どもたちひとりひとりの表情、行動に、あらためて大きな成長を実感しています。みんな元気に新年度を迎えられるよう、最後のひと月、たいせつに過ごしましょう。",
      },
      {
        title: "耳を健康に！",
        body: "３月３日は耳の日です。ちょっとした不注意が、中耳炎・外耳炎の原因に\nなることもあります。日ごろから気をつけるようにしましょう。",
      },
      {
        title: "子どもも花粉症に",
        body: "最近は、園でも花粉症の子どもが増えています。花粉症の場合、外出から戻ってきたら、衣服についた花粉を払い落とし、手や顔を洗うことでずいぶん違います。また、外出前に目薬や点鼻薬を差すのも有効です。\n抗アレルギー剤を症状の出る季節の前から服用すると、花粉の季節になっても軽く済みます。症状がひどい場合は、医師に相談してみてください。",
      },
      {
        title: "",
        body: "",
      },
      {
        title: "進級・就学を控えて",
        body: "気持ちが不安定になる時期です。\n新年度を控え、多くの子どもたちが、「１つ大きくなる」という喜びでいっぱいな時期です。ただ、なかには、大人の期待を過剰に受け止めて、気持ちが不安定になる子どももいます。\n今までなかったのに、急に次のようなようすが見られたら、何かストレスを感じていることがないかどうか、注意してみてください。\n●目をパチパチさせる\n●せきばらいをする\n●肩をぴくっと動かす　……など",
      },
    ],
    4: [
      {
        title: "",
        body: "ご入園、ご進級おめでとうございます。新年度を迎え、ひとつ大きくなった！という喜びと期待いっぱいの笑顔に出会うことができ、とてもうれしく思います。\nことしも保健に関する情報を「ほけんだより」を通してお知らせしていきます。",
      },
      {
        title: "年間保健行事予定",
        body: "＜毎月＞　第１○曜日　健康診断\n○日	身体測定\n＜５月＞	ぎょう虫検査\n＜６月＞	歯科検診\n尿検査\n＜11月＞	ぎょう虫検査\n※つごうにより日程が変更になることがあります。\nそのつど事前にご連絡します。",
      },
      {
        title: "園で預かる薬について",
        body: "基本的に園での与薬は行っていません。病院にかかる場合には、園に通っていることを伝えたうえで、処方の回数を１日２回に変更できないかなど、担当医師に相談してみてください。\nなお、下記の薬については、例外的に園で与薬を行う場合があります。ご相談ください。",
      },
      {
        title: "",
        body: "●熱性けいれん、てんかんの防止薬\n●慢性疾患(心臓病など)の治療薬\n●抗アレルギー薬・ぜんそく薬\n●アトピー性皮膚炎・湿疹などの軟こうなど\nその他、薬に関してご不明な点がありましたら、看護師・保育者にお問い合わせください。",
      },
      {
        title: "生活リズムを整えましょう",
        body: "新年度、環境の変化などにより体調を崩しやすい時期です。元気に過ごすためにも、規則正しい生活リズムを心がけましょう。\n早寝早起きを心がけましょう\n夜は９時ごろまでには寝るようにし、朝は7時に起きるようにがんばりましょう。十分な睡眠は「日中の疲れをとる」「体の成長を促す」「病気を治す力が増す」ことにつながります。",
      },
      {
        title: "",
        body: "朝ごはんをきちんと食べましょう\n朝食を食べることで、体が目覚め、その日の体のリズムが始まります。よくかんで、しっかり食べましょう。\n朝、家で排便をしてきましょう\n朝ごはんを食べると、腸の働きが活発になり、便が出やすくなります。朝はどうしてもあわただしくなりがちですが、十分なトイレタイムをとれるよう、家族全員で朝の生活を見直しましょう。",
      },
      {
        title: "4月5日は身体測定です",
        body: "全園児対象です。当日は次のことに気をつけてください。\n●着脱しやすい服装で来てください。\nまた、服には記名も忘れずに。\n●髪の毛を結んでいる場合は、頭の上でなく、横でしばるようにしてください。",
      },
    ],
    5: [
      {
        title: "",
        body: "新緑の美しい５月。元気にあそぶ子どもたちの姿に、さわやかな風が吹き抜ける思いがします。\n楽しいゴールデンウイークですが、休み明けは心身が少し不安定になりがちな、難しい時季でもあります。ご家庭と連携をとりながら、お子さんのようすに、特に注意を払ってまいります。",
      },
      {
        title: "ぎょう虫検査のお知らせ",
        body: "５月△日が提出日です。\n朝起きてすぐ（＝排尿／排便の前に）、\n行うようにしてください。",
      },
      {
        title: "5月△日は健康診断です",
        body: "内科健診のほか、歯科健診も行います。\n当日は、着脱しやすい服装でお願いし\nます。なお、特に気になることがござ\nいましたら、事前に担任までお知らせ\nください。",
      },
      {
        title: "つめを切って清潔に",
        body: "つめが伸びていると、つめと指の間に\n雑菌が繁殖しやすくなります。乳幼児\nのやわらかい肌を傷つける危険も高ま\nりますので、つめは、こまめに切るよ\nうにしましょう。\nただし、深づめには要注意！\n切りすぎて肌を傷つけ、化膿してしま\nうこともあります。",
      },
      {
        title: "知っておきたい！",
        body: "Ｑ．ぎょう虫ってなに？\nＡ．長さ１ｃｍくらいの寄生虫で、口か\nら入って腸の中で成虫になり、肛門\n周辺に卵を産みつけます。\nＱ．どんな症状？\nＡ．おしりがかゆくなり、夜泣きや睡眠\n不足の原因になることもあります。",
      },
      {
        title: "",
        body: "Ｑ．ほかの人にうつるの？\nＡ．おしりをかいて卵がついた手であち\nこち触ったり、着替えで空気中に卵\nが散らばったりすると、ほかの人が\n口から感染することがあります。\nＱ．検査で陽性だったら？\nＡ．医師と相談のうえ、駆虫薬を内服し\nていただきます。\n（家族全員の服用が基本です）",
      },
      {
        title: "",
        body: "Ｑ．予防するには？\nＡ．主に次のことに気をつけましょう。\n①　手洗い、うがい、入浴など\n清潔に心がける。\n②　つめはこまめに切る。\n③　ていねいに掃除機をかけ、\n室内を清潔に。\n④　寝具を日光に当てて干す。\n（室内でハタキをかけない)",
      },
    ],
    6: [
      {
        title: "",
        body: "梅雨の季節になりました。湿度・温度ともに高くなり、天気も急に変わりがちなこの時期は、一年の中でも、めだって体調を崩しやすい季節でもあります。\n過ごしやすい環境作りに配慮し、お子さまのようすの変化に、日々気を配っていきましょう。",
      },
      {
        title: "６月４～１０日は、歯と口の健康週間",
        body: "歯みがきの習慣づけが、むし歯予防の第一歩。\n園では昼食後、歯みがき指導を行っています。ご家庭でも、毎食後すぐに磨く習慣をつけ、歯磨きの最後には、かならず大人が確認するようにしましょう。",
      },
      {
        title: "正しく磨くためのポイント",
        body: "●　ブラシやグリップの形がシンプル\nで、小さめの歯ブラシを使う。\n●　磨く面に直角にブラシを当てる。\n●　ブラシは、柔らかい力で、できる\nだけ細かく動かす。\n●　歯を１本１本磨くつもりで、しっ\nかり歯磨きをおこなう。",
      },
      {
        title: "もしかして…むし歯？",
        body: "食事中こんなようすが見られること、ありませんか？\nひょっとして、むし歯ができているのかも‥‥。\n▲　左右、同じほうでばかり噛んでいる。\n▲　いつまでも飲み込まない。\n▲　かまないで丸飲みする。",
      },
      {
        title: "食中毒に要注意",
        body: "温度も湿度も高くなる梅雨時は、食中毒が多く発生する時期です。\n◎　お弁当は、当日の朝に十分加熱\nしたものを入れましょう。\n◎　熱いものと冷たいものを、\nいっしょに入れない。\n◎　お弁当箱は、熱湯消毒を心がけ\nてくださいね　♪",
      },
      {
        title: "プールが 始まります",
        body: "○日はプール開き。 \nその後は、天候がよい日は毎日、プールあそびを行います。\n元気に楽しくあそぶためにも、毎朝の健康チェックとプールカードご記入を、忘れずにお願いいたします。",
      },
    ],
    7: [
      {
        title: "",
        body: "日ざしが日増しに強くなり、心弾む夏がやってきました。 天気がよければ毎日プール！の子どもち。\n元気そうに見えても、体は意外と疲れているものです。おうちではゆったりと過ごせるよう、一層の\nご配慮をお願いいたします。",
      },
      {
        title: "プールチェック",
        body: "子どもたちの大好きなプールがシー\nズンまっ盛り。プールで元気に遊べ\nるように、プールカードご記入と\n体調チェックを:毎朝お願いします。\n□熱は何度ですか？\n37.5℃以上がプールに入れない\n目安ですが、平熱は子どもによ\nって違うので、平熱を考慮して\n判断します。\n□前日、よく眠れましたか？\n□目やにや充血はありませんか？\n昼間も目やにや充血が見られる\nようなら、結膜炎も疑われます。\n□せき・鼻水は出ていませんか？\n発熱／食欲不振などの全身症状\nがなければまず心配ありません。\n□皮膚に異常はありませんか？\n傷やただれがあり乾燥してない\n場合はプールは避けましょう。\nお心当たりの点が少しでもござ\nいましたら、直接、保育者あて\nご相談ください。",
      },
      {
        title: "夏にはやる病気",
        body: "夏の感染症のうち、特に気をつけたいもの３つと、その症状を挙げました。\nこんな症状が出たら、すぐ病院へ！\n▲ ヘルパンギーナ\n高熱とのどの痛み。のどに水ほ\nうや潰瘍ができるとかなり痛む。\n（乳児はミルクが飲めないほど）\n▲ プール熱（咽頭結膜熱）\n高熱とのどの痛みのほか、目の\n痛み・かゆみ・充血など。結膜\n炎に似た症状がでる。\n▲ 手足口病\n手のひらや足の裏、口の中に、\n小さな発しんや、水ほうがで\nきる。熱が出ることも。",
      },
      {
        title: "熱中症に注意！",
        body: "９月ごろまでは、紫外線の多い時期。\n熱中症を防ぐためにも、外に出るとき\nは、必ず帽子をかぶらせましょう。\n帽子は、つばが広く、通気性のよい\n素材のものがおすすめです。",
      },
      {
        title: "いよいよ夏休み！",
        body: "２△日からは、 お待ちかねの夏休み！心身の健康のためにも、規則正しい生活を心がけて過ごしましょう。\n休み明け、また元気な顔に会えるのを楽しみにしていますね。",
      },
    ],
    8: [
      {
        title: "",
        body: "毎日プールあそびに興じる子どもたち。夏まっさかり！　ですね。\nさてこの夏、旅行など遠出の計画を立てているご家庭も多いかと思います。\n外出先では健康と安全に気を配り、楽しい思い出をたくさん作ってきてくださいね。",
      },
      {
        title: "暑い夏を元気に乗り切るポイント",
        body: "① 水分補給\n大量に汗をかくと、脱水症状を起こし、熱中症の原因にもなります。\nこまめな水分補給を心がけましょう。\n② 皮膚のケア\nこまめに汗をふいたり、シャワーを浴びたりして、皮膚を清潔に保ちましょう。\n③ 紫外線対策\n外出時は帽子をかぶり、日焼け止めを塗るなどの紫外線対策も忘れずに。\n④ 室温管理\n高温・多湿などの条件がそろえば、たとえ室内でも熱中症は起こります。エアコンを\n上手に使いましょう。ただし、冷やしすぎに注意！　設定温度は目安27～28℃です。",
      },
      {
        title: "野山に出かけるときは",
        body: "自然の豊かな野外に出かけるときは、虫刺されや植物かぶれが心配ですね。野外での服装\nや虫刺されの避けかたなどの注意ポイントをまとめてみました。\n¶ 白っぽい帽子\nスズメバチは黒や黄色のものを攻撃する習性アリ。帽子は白っぽいものをかぶる。\n¶ 長そで＆長ズボン\n草木の多い場所では、露出の少ない服装にする。\n¶ フリルのついた服を避ける\nひらひらした服は、ハチを巻き込むことがある。\n¶ 大声で騒がない\n大きな音はハチを刺激しやすい。大声で騒いだり、音楽をかけたり、を避ける。",
      },
      {
        title: "水分補給の落とし穴",
        body: "水分補給はたいせつですが、糖分の多い飲み物をとりすぎると、食欲が落ち、体力低下にもつながります。ジュースはもちろんのこと、スポーツドリンクにも糖分の高いものが、けっこうあります。気をつけましょう。",
      },
      {
        title: "８月７日は「鼻の日」",
        body: "鼻をほじるくせのある子は、鼻の粘膜が弱くなり、鼻血が出やすくなります。無意識のうちにやってしまうようなら、寝ている間は手袋をさせるのもいいでしょう。",
      },
    ],
    9: [
      {
        title: "",
        body: "暑い夏をエネルギッシュに過ごしてきた子どもたち。そろそろ夏の疲れが出てくるかもしれません。\n園ではこれから、運動会や遠足といった行事が目白押しの季節を迎えます。\n子どもたちが元気に活動できるよう、いま一度、子どもの健康生活を見直してみましょう。",
      },
      {
        title: "９月１日は防災の日",
        body: "非常用品は備えていますか？　\nもしものとき、すぐに取り出せるよう、決まった場所に用意しておきましょう。また、定期的に中身をチェックし、水や食料品など、期限切れの物が入っていることのないようにしましょう。",
      },
      {
        title: "生活リズムを見直そう",
        body: "生活リズムの乱れは、健康の大敵！\n夏のうちに、遅寝遅起きの習慣がついていませんか？　早いうちに、生活リズムを取り戻しましょう。\nひとまず、朝の生活習慣の見直しから始めてみませんか？\n◎ まずは早起き\n遅寝を早寝に変えるのは、とても\n難しいもの。最初のうちは、早く\n寝ることから始めましょう。遅く\n寝ても、いつも同じ時間に起きる\nようにします。",
      },
      {
        title: "",
        body: "◎ 光を浴びる\n朝、起きたら、部屋のカーテンを\n開け、太陽の光を浴びましょう。\nそれだけで体内時計がリセットさ\nれ、体も脳も目覚めます。\n◎ 朝食をとる\n朝食で胃腸を働かせると、体から\n目覚めてきます。食欲がなければ\nコップ一杯の牛乳だけでも。",
      },
      {
        title: "救急用品を点検しましょう",
        body: "９月９日は救急の日です。この機会に、ご家庭の救急用品をひととおりチェックしてみましょう。古い薬は処分し、新しいものを補充していきます。\n□ 体温計　　　□ ばんそうこう\n□ 包帯　　　　□ ガーゼ\n□ 脱脂綿　　　□ 綿棒\n□ ピンセット　□ 毛抜き\n□ はさみ　　　□ 水まくら\n□ 湿布薬　　　□ 化膿止め\n□ 虫刺され用の塗り薬\nいざというときに慌てたりしないよう、救急箱は、取り出しやすい場所に保管しておきましょう。",
      },
    ],
    10: [
      {
        title: "",
        body: "スポーツの秋、収穫の秋、食欲の秋……。\n園では、運動会、遠足、と楽しい行事が続きます。子どもの体力もぐんと伸びる時期。\n楽しい経験を通して、健康な体をはぐくんでいきましょう。",
      },
      {
        title: "10月10日は目の愛護デー",
        body: "２つの10を横に倒すと、まゆと目の形に見えることから、10月10日は目の愛護デーとされています。乳幼児期は、子どもの目がもっとも育つ時期。この機会に、子どもの「目」の健康をあらためて見直してみましょう。",
      },
      {
        title: "「見る力」を育てるポイント",
        body: "□明るさ、暗さのメリハリある生活を\n日中は光を浴び、夜は暗くして眠る。\n□広い空間で体と目を動かす機会を\n全身運動は眼球、視神経・脳の発達を促す。",
      },
      {
        title: "",
        body: "□いろいろなものを見る体験を\n止まっているもの動いているもの、小さなもの大きいもの、遠くにあるもの近くにあるものなど、いろいろなものを見る体験が、目によい刺激になる。",
      },
      {
        title: "足に合った靴で 元気にあそぼう！",
        body: "すぐに成長するからと、つい、合わない靴をはかせていませんか？　靴が足に合っていないと、不自然な足の使いかた、歩きかたのまま足が育つことになります。成長著しいこの時期こそ、適切な靴選びが重要なのです。\nつま先にゆとりがある\n理想は５ｍｍ。すぐに成長することを考え、５～９ｍｍのゆとりがあるものを。指が自由に動かせるように、つま先が広がって厚みがあることもだいじ。",
      },
      {
        title: "",
        body: "調整ベルトがついている\n足を固定し、足と靴を一体化させる。\n柔らかく、クッション性のある靴底\n足の動きにフィットする柔らかさと、地面からの衝撃を和らげるクッション性が必要。",
      },
      {
        title: "「あぶら」のとりすぎ 要注意",
        body: "食欲おうせいは．とてもよいことですが、食事の「質」にも気を配りましょう。例えば脂質は、１gあたりのエネルギーが他の栄養素と比べて高く、吸収率も高いため、脂質の摂取率が高いと肥満になりやすいといわれています。脂質の多いファストフードやスナック菓子のとりすぎには、十分注意してください。",
      },
    ],
    11: [
      {
        title: "",
        body: "朝夕の冷え込みが厳しくなってきました。一日のうちの寒暖差が激しいと、かぜをひきやすくなります。\nこれからやってくる本格的な冬に向けて、かぜに負けないじょうぶな体作りを心がけましょう。",
      },
      {
        title: "インフルエンザの予防接種を",
        body: "子どもの場合は、１シーズン２回接種が標準です。接種してから、２週間以上たたないと効果が現れないので、接種するかたは、12月の流行期に入る前に済ませるようにしましょう。",
      },
      {
        title: "だいじょうぶ？鼻水・鼻づまり",
        body: "鼻水や鼻づまりをほうっておくと、鼻やのどの粘膜が炎症を起こし、ほかの病気の原因になることもあります。鼻水・鼻づまりが気になったら、早めに病院を受診しましょう。\nそして家庭では、鼻水が出たらふく、鼻がつまったらかむ、という生活習慣が身につくよう、繰り返し伝えていきましょう。",
      },
      {
        title: "動きやすい服装で",
        body: "最近の子どもたち、とってもおしゃれになりましたね。ただ、ふだんの服装は、「活発にあそべる」という点を優先していただきたいと思います。これから訪れる寒い冬も快適に過ごす服装を、考えてみましょう。\n＜＜下着を着る＞＞\n保温性・吸湿性を考えると、綿100％で半そでのものがいちばん。ただ、古くなると目が詰まって保温力が低下するので気をつけましょう。Ｔシャツは、下に着るとごろごろして動きにくくなるので、下着の代わりにはなりません。\n＜＜薄手の重ね着＞＞\n重ね着をすると、服と服の間に体温で温まった空気の層ができ、厚手のもの１枚着るより保温性が高まります。薄手の重ね着なら、動きやすく機能的です。\n＜＜着脱しやすいもの＞＞\nファッション性よりも、ボタンやホックをはめやすく、ひとりで着脱しやすいものがよいでしょう。",
      },
      {
        title: "健康な体作りが、 かぜの予防に",
        body: "気温が下がり、空気が乾燥してくると、気をつけたいかぜ。うがいと手洗いが、かぜの予防の基本です。習慣づけるようにしましょう。また、規則正しい生活で、ウイルスを寄せつけない強い体を作ることも、大きなかぜ予防になります。ご家族みなさんで、気をつけていきましょう。\n・せっけんで手を洗う\n・がらがらうがい\n・３食しっかり食べる\n・早寝早起き",
      },
    ],
    12: [
      {
        title: "",
        body: "年の瀬を迎え、何かとあわただしい12月。生活リズムが崩れがちで、また感染症もはやる時期です。子どもたちにとっては、クリスマスに冬休みと、お楽しみがいっぱいの月ですが、体調管理には十分気を配っていきましょう。",
      },
      {
        title: "１２月○日、冬至です",
        body: "北半球では、夜がいちばん長く、昼がいちばん短い日。この日にユズ湯に入り、カボチャを食べるとかぜをひかないという習わしがあります。\n実際、カボチャに豊富に含まれるビタミンＡには、皮膚や粘膜、視力、骨や歯にも効果があります。またユズには、新陳代謝を活発にして血管を拡張、血行促進の作用、さらに鎮痛・殺菌作用のある成分が含まれます。これが、体が温まり、かぜ予防にもなるといわれるゆえんでしょう。冬至には、カボチャとユズの２大強力パワーをもらって、冬を元気に乗り切りましょう！",
      },
      {
        title: "ノロウイルスに要注意",
        body: "冬場に増えるノロウイルスは、１０月から２月ごろまで感染のシーズンが続きます。感染者のおう吐物、排せつ物からうつる２次感染のケースが多いようです。感染すると、下痢やおう吐、腹痛があり、３８度以上の発熱も伴います。今のところ、感染予防のワクチンや治療薬は開発されていないため、症状が出たら水分を補給しながら回復を待つしかありません。",
      },
      {
        title: "",
        body: "予防としては、感染例が報告されている間は二枚貝は加熱して食べる、食事の際にはせっけんでよく手を洗うなどです。ノロウイルスを殺すことはできませんが、手から洗い流すことができます。感染者は症状が回復しても、その後１週間～１か月間、排せつ物とともにウイルスが体外に出るといわれています。",
      },
      {
        title: "やけどをしたら…",
        body: "冬はやけどの事故が増える時期。万が一、やけどをしてしまったら、とにかく流水で冷やすこと。その後、症状によって病院で診てもらいましょう。\nアロエやみそを塗るなど、民間療法でよいといわれていることを、安易に試すのはやめましょう。細菌感染の原因になることがあり、症状を悪化させ、長引かせてしまいます。",
      },
      {
        title: "もうすぐ冬休み",
        body: "子どもたちが心待ちにしているクリスマスにお正月。年末年始は、外出や来客なども多く、生活リズムが乱れがちです。休み中も、早寝早起きを心がけ、規則的な生活リズムを崩さないように気\nを配りましょう。新しい年、\nまた元気な顔に会えることを\n楽しみにしています。",
      },
    ],
  },
};
