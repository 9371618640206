
import ApiLetter from "@/api/ApiLetter";
import { templateText } from "@/constants/letter";
import LocalDataService from "@/service/LocalDataService";
import { LetterResponse } from "chaild-api/lib";
import Vue from "vue";

type LetterTextObject = {
  text: string;
  style: string;
};

type LetterObject = {
  style: string;
  title: LetterTextObject;
  body: LetterTextObject;
};

const LetterTitleStyle =
  "width: 100%; height: 16px; font-size: 12px; text-align: center;";
const LetterBodyStyle = "width: 100%; height: 90%; font-size: 11px;";

const displayNone = "display: none;";
const LetterMetaTitleStyle =
  "position: absolute; top: 50px; width: 100%; height: 50%; text-align: center; vertical-align: middle;";

export default Vue.extend({
  name: "LetterEdit",

  async mounted() {
    console.log(this.designSlug);
    console.log(this.letterId);
    console.log(this.isUpdate);
    console.log(this.url);
    console.log(this.category);

    if (this.isUpdate) {
      await this.getLetter();
    }
  },

  data() {
    return {
      loading: false,
      letterInput: {
        date: "",
        createdBy: "",
        name: "",
        className: "",
      },
      letterResponse: null as null | LetterResponse,
      metaTitleTypeItems: [
        { text: "なし", value: "none" },
        { text: "文字", value: "text" },
      ],
      metaTitleItems: [
        { text: "1rem", value: "font-size: 1rem;" },
        { text: "2rem", value: "font-size: 2rem;" },
        { text: "3rem", value: "font-size: 3rem;" },
        { text: "4rem", value: "font-size: 4rem;" },
        { text: "5rem", value: "font-size: 5rem;" },
      ],
      healthLetterBody: [
        {
          style:
            "position: absolute; top: 0px; left: 0px; height: 160px; width: 100%; background: white; vertical-align: center;",
          meta: {
            titleType: "none",
            titleFontSize: "font-size: 1rem;",
          },
          title: {
            text: "",
            style: LetterMetaTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle + displayNone,
          },
        },
        {
          style:
            "position: absolute; top: 215px; left: 57px; height: 192px; width: 209px;",
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
        {
          style:
            "position: absolute; top: 429px; left: 57px; height: 322px; width: 209px;",
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
        {
          style:
            "position: absolute; top: 198px; left: 343px; height: 420x; width: 209px;",
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
      ] as LetterObject[],
      nurseryLetterBody: [
        {
          style:
            "position: absolute; top: 20px; left: 20px; height: 140px; width: 48%; background: white; vertical-align: center;",
          meta: {
            titleType: "none",
            titleFontSize: "font-size: 1rem;",
          },
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle + displayNone,
          },
        },
        {
          style:
            "position: absolute; top: 170px; left: 37px; height: 50px; width: 348px;",
          title: {
            text: "",
            style: LetterTitleStyle + displayNone,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
        {
          style:
            "position: absolute; top: 260px; left: 37px; height: 80px; width: 348px;",
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
        {
          style:
            "position: absolute; top: 379px; left: 37px; height: 181px; width: 348px;",
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
        {
          style:
            "position: absolute; top: 48px; left: 463px; height: 120px; width: 325px;",
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
        {
          style:
            "position: absolute; top: 236px; left: 463px; height: 120px; width: 325px;",
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
        {
          style:
            "position: absolute; top: 424px; left: 463px; height: 120px; width: 325px;",
          title: {
            text: "",
            style: LetterTitleStyle,
          },
          body: {
            text: "",
            style: LetterBodyStyle,
          },
        },
      ] as LetterObject[],
      templateText: templateText,
    };
  },

  computed: {
    designSlug: function (): any {
      return this.$route.query.designSlug;
    },
    url: function (): any {
      if (this.isUpdate) {
        return this.letterResponse?.design.url || "";
      } else {
        return this.$route.query.url;
      }
    },
    month: function (): any {
      return this.$route.query.month;
    },
    isFullColor: function (): any {
      return this.$route.query.isFullColor;
    },
    category: function (): any {
      if (this.isUpdate) {
        return this.letterResponse?.design.category || "healthLetter";
      } else {
        return this.$route.query.category;
      }
    },
    letterId: function (): any {
      return this.$route.params.letterId;
    },
    isUpdate: function (): boolean {
      return !!this.letterId;
    },
  },

  methods: {
    async getLetter() {
      if (this.letterId) {
        this.loading = true;
        const response = await ApiLetter.getLetter({
          letterId: this.letterId,
        }).catch(() => (this.loading = false));
        if (response) {
          this.letterResponse = response;
          this.letterInput = {
            date: response.date,
            createdBy: response.createdBy,
            name: response.name,
            className: response.className,
          };
          if (response.design.category == "healthLetter") {
            this.healthLetterBody = response.body;
          } else {
            this.nurseryLetterBody = response.body;
          }
        }
        this.loading = false;
      }
    },
    async createLetter() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.designSlug) {
        this.loading = true;
        const response = await ApiLetter.createLetter({
          nurseryId,
          ...this.letterInput,
          designSlug: this.designSlug,
          body:
            this.category == "healthLetter"
              ? this.healthLetterBody
              : this.nurseryLetterBody,
        }).catch(() => (this.loading = false));
        if (response) {
          alert("作成しました");
          this.$router.push({ path: "/letter/list" });
        }
      }
      this.loading = false;
    },
    async updateLetter() {
      const nurseryId = LocalDataService.getNurseryId();
      if (nurseryId && this.letterResponse) {
        this.loading = true;
        const response = await ApiLetter.updateLetter({
          letterId: this.letterId,
          ...this.letterInput,
          designSlug: this.letterResponse.design.designSlug,
          body:
            this.category == "healthLetter"
              ? this.healthLetterBody
              : this.nurseryLetterBody,
        }).catch(() => (this.loading = false));
        if (response) {
          alert("更新しました");
          // this.$router.push({ path: '/letter/list' });
        }
      }
      this.loading = false;
    },
    async exportLetter() {
      if (this.letterId) {
        this.loading = true;
        const response = await ApiLetter.exportLetter({
          letterId: this.letterId,
        }).catch(() => (this.loading = false));
        if (response) {
          // alert('エクスポートを開始しました。\nダウンロード一覧画面からご確認ください。')
          window.open(response.preSignedDownloadUrl, "_blank");
        }
        this.loading = false;
      }
    },
    setTemplateValues(month: number) {
      this.loading = true;
      if (this.category == "healthLetter") {
        for (let i = 1; i < this.healthLetterBody.length; i++) {
          this.healthLetterBody.splice(i, 1, {
            style: this.healthLetterBody[i].style,
            title: {
              text: this.templateText.healthLetter[month][i - 1].title,
              style: this.healthLetterBody[i].title.style,
            },
            body: {
              text: this.templateText.healthLetter[month][i - 1].body,
              style: this.healthLetterBody[i].body.style,
            },
          });
        }
      } else {
        for (let i = 1; i < this.nurseryLetterBody.length; i++) {
          this.nurseryLetterBody.splice(i, 1, {
            style: this.nurseryLetterBody[i].style,
            title: {
              text: this.templateText.nurseryLetter[month][i - 1].title,
              style: this.nurseryLetterBody[i].title.style,
            },
            body: {
              text: this.templateText.nurseryLetter[month][i - 1].body,
              style: this.nurseryLetterBody[i].body.style,
            },
          });
        }
      }
      this.loading = false;
    },
  },
});
